import JSEncrypt from 'jsencrypt/bin/jsencrypt.min';

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey =
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQClafI67AUD5BgsuNz/DRyx16r5iJE/t9hJtXCInrEZaDcDBfgj2P43url1bvbShkILu/684QdVUX4N2P6bz8JVmwvb8Cv2MMpMvfbKpELm6BKDVDSPP7qYkQQyg27yM14ntwdW1QMigbhxMj3hQvRHHlVLH/JEo37EXvI2XziytwIDAQAB';


// 加密
export function encrypt(txt) {
    const encryptor = new JSEncrypt();
    encryptor.setPublicKey(publicKey); // 设置公钥
    return encryptor.encrypt(txt); // 对需要加密的数据进行加密
}

